export class MessageQueueService {
    private _connection: IConnection;
    private _queue: any[];

    constructor() {
        this._connection = null;
        this._queue = [];
    }

    public setConnection(connection: IConnection) {
        this._connection = connection;
        this.processQueue();
    }

    public add<TBody>(message: IMessage<TBody>) {
        this._queue.push(message);
        this.processQueue();
    };

    private processQueue() {
        if (!this._connection || !this._queue.length) {
            return;
        }

        var message = this._queue.splice(0, 1)[0];

        this._connection.sendMessage(message);

        this.processQueue();
    }
}

export interface IConnection {
    sendMessage(message: IMessage<any>): void;
}

export interface IMessage<TBody> {
    type: string;
    body?: TBody;
}
