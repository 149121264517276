import {ISetMode, ISwipeData, IRetrievePaymentTokenInfo} from '../receiver-contracts';
import {Service} from './service';
import {ServiceRegistry} from './service-registry';
import {ICustomerInfo} from '../server/services/api4';
import {ICreateAccountContext, ICustomerToken} from '../sender-contracts';
import {IAuth} from '../client-options';

export class SenderRegistry extends Service {
    constructor(services: ServiceRegistry) {
        super(services);
    }

    //noinspection JSUnusedGlobalSymbols
    public setMode(mode: string) {
        this.services.messageQueue.add<ISetMode>({
            type: 'setMode',
            body: {
                mode: mode
            }
        });
    }

    //noinspection JSUnusedGlobalSymbols
    public setSwiperFocus() {
        this.services.messageQueue.add({
            type: 'setSwiperFocus'
        });
    }

    //noinspection JSUnusedGlobalSymbols
    public retrieveAccount(customerInfo: ICustomerInfo) {
        this.services.messageQueue.add<ICustomerInfo>({
            type: 'retrieveAccount',
            body: customerInfo
        });
    }

    //noinspection JSUnusedGlobalSymbols
    public matchOrCreateAccount(customerToken: ICustomerToken) {
        this.services.messageQueue.add<ICustomerToken>({
            type: 'matchOrCreateAccount',
            body: customerToken
        });
    }

    //noinspection JSUnusedGlobalSymbols
    public dedupeAccount() {
        this.services.messageQueue.add({
            type: 'dedupeAccount'
        });
    }

    //noinspection JSUnusedGlobalSymbols
    public createAccount(createAccountContext: ICreateAccountContext) {
        this.services.messageQueue.add<ICreateAccountContext>({
            type: 'createAccount',
            body: createAccountContext
        });
    }

    //noinspection JSUnusedGlobalSymbols
    public retrievePaymentOptions() {
        this.services.messageQueue.add({
            type: 'retrievePaymentOptions'
        });
    }

    //noinspection JSUnusedGlobalSymbols
    public retrievePaymentToken(retrievePaymentTokenInfo: IRetrievePaymentTokenInfo) {
        this.services.messageQueue.add<IRetrievePaymentTokenInfo>({
            type: 'retrievePaymentToken',
            body: retrievePaymentTokenInfo
        });
    }

    //noinspection JSUnusedGlobalSymbols
    public setSubmitted() {
        this.services.messageQueue.add({
            type: 'setSubmitted'
        });
    }

    //noinspection JSUnusedGlobalSymbols
    public focusFirst() {
        this.services.messageQueue.add({
            type: 'focusFirst'
        });
    }

    //noinspection JSUnusedGlobalSymbols
    public focusLast() {
        this.services.messageQueue.add({
            type: 'focusLast'
        });
    }

    //noinspection JSUnusedGlobalSymbols
    public authUpdated(auth: IAuth) {
        if (auth) {
            auth.isLoggedIn = auth.isLoggedIn || false;
        }

        this.services.messageQueue.add<IAuth>({
            type: 'authUpdated',
            body: auth
        });
    }

    //noinspection JSUnusedGlobalSymbols
    public disableForm() {
        this.services.messageQueue.add({
            type: 'disableForm'
        });
    }

    //noinspection JSUnusedGlobalSymbols
    public enableForm() {
        this.services.messageQueue.add({
            type: 'enableForm'
        });
    }

    public verifyAccount(customerToken: ICustomerToken, existingCustomerAccountId: number, customerId: number){
        this.services.messageQueue.add({
            type: 'verifyAccount',
            body: {
                existingCustomerAccountId: existingCustomerAccountId,
                customerToken: customerToken,
                customerId: customerId
            }
        })
    }
    
}
