export class Environment {
    public static instance: Environment = new Environment();
    private _urlRoot: string;

    constructor() {
        this._urlRoot = '%http_protocol%://%api_prefix%.%paysimple_domain%';
    }

    get urlRoot(): string {
        return this._urlRoot;
    }
}
