import {MessageQueueService} from '../message-queue-service';
import { SwipeKeyHelper } from '../server/services/swipe-key-helper';

export class ServiceRegistry {
    private _messageQueue: MessageQueueService;
    private _swipeKeyHelper: SwipeKeyHelper;

    public get messageQueue(): MessageQueueService {
        return this._messageQueue || (this._messageQueue = new MessageQueueService());
    }

    public get swipeKeyHelper(): SwipeKeyHelper {
        return this._swipeKeyHelper || (this._swipeKeyHelper = new SwipeKeyHelper());
    }
}
