
export class SwipeKeyHelper {
    private _altKeys = '';
  
    getKeyData(event: KeyboardEvent): string | null {
      let char = event.key;
      const charUpper = char.toUpperCase();
  
      // idtech sends the alt code ALT+013 for a newline instead of enter.
      if (charUpper === 'ALT' && this._altKeys === '013') return '\n';
  
      if (char.length > 1) {
        if (charUpper === 'ENTER') char = '\n';
        // IE sets the space key as 'Spacebar' instead of ' '
        else if (charUpper === 'SPACEBAR') char = ' ';
        else return null;
      }
      if (event.altKey) {
        this._altKeys += char;
        return null;
      } else this._altKeys = '';
  
      return char;
    }
  
    // have to use UIEvent since ClipboardEvent doesn't work with IE
    getPasteData(event: UIEvent): string | null {
      let pastedValue = '';
      const e = event as any;
  
      if (e.clipboardData && e.clipboardData.getData) {
        pastedValue = e.clipboardData.getData('text/plain');
      } else {
        // IE does things differently
        const clipboardData = (window as any).clipboardData;
  
        if (clipboardData && clipboardData.getData) {
          pastedValue = clipboardData.getData('Text');
        }
      }
  
      return pastedValue || '';
    }
  }
