import { IConnection, IMessage } from "../message-queue-service";
import { IClientOptions, IClientOptionsStyles, StyleType, IClientOptionsStylesByKey, IStyleDictionary, IAuth, CvvMode, IClientOptionsText, CompanyNameMode } from "../client-options";
import { ReceiverRegistry } from "./receiver-registry";
import { SenderRegistry } from "./sender-registry";
import { PsWindow } from "../window";
import { ServiceRegistry } from "./service-registry";
import { Environment } from "../environment";
import { Skin } from "../skin";
import { SwipeKeyHelper } from "../server/services/swipe-key-helper";
import { ISwipeData } from "../receiver-contracts";
import { ILogMessage, LogLevel } from "../server/services/krypton.service";

let iframeSrc = Environment.instance.urlRoot + "/paysimplejs/v1/index.html";
let targetOrigin = "*";

let isSwipe: boolean = false;

let fieldKeys: string[] = [
    "cardNumber",
    "cardInfo",
    "expiration",
    "cvv",
    "postalCode",
    "routingNumber",
    "accountNumber",
    "accountType",
    "bankName",
    "companyName"
];

if (!(window as PsWindow).MessageChannel) {
    throw new Error("MessageChannels are not supported.");
}

(window as PsWindow).paysimpleJs = function (options: IClientOptionsWithContainerRaw) {
    if (!options) {
        throw new Error("No options were passed in.");
    }

    if (!options.auth) {
        throw new Error("Required property \"auth\" was not set.");
    }

    options.preventAutocomplete = options.preventAutocomplete || false;

    options.shrinkFields = options.shrinkFields || false;

    options.auth.isLoggedIn = options.auth.isLoggedIn || false;
    // if null or undefined default to true.
    options.auth.autoRefresh = (options.auth.autoRefresh == null) ? true : options.auth.autoRefresh;

    options.enableCaptcha = options.enableCaptcha || false;

    options.enableCaptchaInteraction = options.enableCaptchaInteraction || false;

    options.recaptchaSiteKey = options.recaptchaSiteKey || undefined;

    let listeners: IListenerDictionary = {};
    let services = new ServiceRegistry();
    let swipeKeyHelper: SwipeKeyHelper = services.swipeKeyHelper;

    let messageQueue = services.messageQueue;

    let iframe = connect(options, handleMessage, function (connection: IConnection) {
        messageQueue.setConnection(connection);

        let origin = window.location.origin;
        if(!origin.startsWith("http")) {
            if(origin == null)
                origin = "null";

            messageQueue.add<ILogMessage>({
                type: "log",
                body: {
                    log_level: LogLevel.Info,
                    message: `PSJS: Window origin is '${origin}'`
                }
            });
        }
    });

    let senders = new SenderRegistry(services);
    let receivers: any = new ReceiverRegistry(services, iframe);

    return {
        iframe: iframe,
        send: senders,
        destroy: destroy,
        on: on,
    };

    function onKeyUp(e: KeyboardEvent){
        if (!isSwipe)
            return;

        e.preventDefault();
        e.cancelBubble = true;
    }

    function onKeyDown(e: KeyboardEvent){
        if (!isSwipe){
            return;
        }
        if((e.key === 'v' || e.key === 'c') && e.ctrlKey)
            return;

        e.preventDefault();
        e.cancelBubble = true;

        let data = swipeKeyHelper.getKeyData(e);
        sendSwipeData(data);
    }

    function onPaste(e: UIEvent){
        if (!isSwipe){
            return;
        }
        e.preventDefault();
        e.cancelBubble = true;

        let data = swipeKeyHelper.getPasteData(e);
        sendSwipeData(data);
    }

    function sendSwipeData(data: string){
        services.messageQueue.add<ISwipeData>({
            type: 'swipeData',
            body: {
                data: data
            }
        });
    }

    function destroy() {
        if (iframe && iframe.parentElement) {
            iframe.parentElement.removeChild(iframe);
        }
        destroyEventListeners();
        iframe = null;
        listeners = {};
    }

    function destroyEventListeners(){
        window.document.removeEventListener('paste', (onPaste as EventListener));
        window.document.removeEventListener('keydown', onKeyDown);
        window.document.removeEventListener('keyup', onKeyUp);
    }

    function createEventListeners(){
        window.document.addEventListener('paste', (onPaste as EventListener));
        window.document.addEventListener('keydown', onKeyDown);
        window.document.addEventListener('keyup', onKeyUp);
    }

    function handleMessage(event: MessageEvent) {
        let message: IMessage<any> = event.data;

        if (options.debug) {
            console.log("client:", message);
        }

        if (!message) {
            throw new Error("No message was sent from server.");
        }

        // if no message type was passed in, short-circuit out of function. Can't throw error because other things outside of our control can interact with iframe (e.g. no idea where {LPMessage:"gotFrameIdentity",frameIdentity:"0:1",requestID:1485809576513} is coming from but it's not our code)
        if (!message.type) {
            return;
        }

        let receiver = receivers[message.type];

        let listeners = getListeners(message.type);

        listeners.forEach(listener => {
            listener.call(null, message.body);
        });

        if (receiver) {
            receiver.call(receivers, message.body);
        }
        else if (message.type === 'modeChanged' && message.body.mode === 'cc-swipe'){
            createEventListeners();
            isSwipe = true;
        }
        else if (message.type === 'modeChanged' && message.body.mode !== 'cc-swipe'){
            isSwipe = false;
            destroyEventListeners();
        }
        else if (message.type === 'cardSwiped'){
            isSwipe = false;
            destroyEventListeners();
        }
        else {
            if (options.debug && !listeners.length) {
                console.warn("Server sent unrecognized message type (\"" + message.type + "\").");
            }
        }
    }

    function on(eventType: string, callback: Function) {
        if (!listeners[eventType]) {
            listeners[eventType] = [];
        }

        listeners[eventType].push(callback);
    }

    function getListeners(eventType: string) {
        return listeners[eventType] || [];
    }
};

function connect(options: IClientOptionsWithContainerRaw, messageHandler: (event: MessageEvent) => void, callback: (connection: IConnection) => void) {
    if (!options.container) {
        throw new Error("container has not been defined.");
    }

    let iframe = document.createElement("iframe");
    iframe.setAttribute("frameBorder", "0");
    iframe.setAttribute("scrolling", "no");
    iframe.setAttribute("allowTransparency", "true");
    iframe.style.width = "100%";
    iframe.src = iframeSrc;
    iframe.onload = onload;

    options.container.appendChild(iframe);
    return iframe;

    function onload() {
        // set up channel that will stream messages back from server
        let streamChannel = new (window as PsWindow).MessageChannel();
        let iframeContents = this.contentWindow;

        streamChannel.port1.onmessage = handleInitMessage;

        let message: IMessage<IClientOptions> = {
            type: "init",
            body: {
                debug: options.debug || false,
                auth: options.auth,
                skin: options.skin || "default",
                styles: processStyles(options.styles),
                cvvMode: options.cvvMode || "required",
                shrinkFields: options.shrinkFields,
                bypassPostalCodeValidation: options.bypassPostalCodeValidation,
                text: processText(options.text),
                showPlaceholder: options.hasOwnProperty("showPlaceholder") ? !!options.showPlaceholder : true,
                preventAutocomplete: options.preventAutocomplete,
                companyNameMode: options.companyNameMode || "hidden",
                businessCheckingEnabled: options.businessCheckingEnabled || false,
                enableCaptcha: options.enableCaptcha || false,
                enableCaptchaInteraction: options.enableCaptchaInteraction || false,
                recaptchaSiteKey: options.recaptchaSiteKey || undefined,
            }
        };

        iframeContents.postMessage(message, targetOrigin, [streamChannel.port2]);

        function sendMessage(message: IMessage<any>) {
            let channel = new (window as PsWindow).MessageChannel();

            iframeContents.postMessage(message, targetOrigin, [channel.port2]);
        }

        function handleInitMessage(event: MessageEvent) {
            let message: IMessage<any> = event.data;

            if (!message) {
                throw new Error("No message was sent from server.");
            }

            if (options.debug) {
                console.log("client:", message);
            }

            if (message.type !== "init") {
                throw new Error("Expected message of type \"init\" but received message of type \"" + message.type + "\".");
            }

            streamChannel.port1.onmessage = messageHandler;

            callback({
                sendMessage: sendMessage
            });
        }
    }

    function toStyleString(style: IStyleDictionary): string {
        if (!style) {
            return "";
        }

        let styleString: string = "";

        Object.keys(style).forEach(key => {
            let validKey = key.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase(); // convert to valid css property(e.g "backgroundColor" to "background-color")
            if (isMergeableObject(style[key])) {
                return;
            }
            let value: string = style[key] as string;
            styleString += `${validKey}:${value};`;
        });

        return styleString;
    }

    function merge(base: IStyleDictionary, byKey: IStyleDictionary): IStyleDictionary {
        let result: IStyleDictionary = {};

        if (base) {
            Object.keys(base).forEach(key => {
                result[key] = base[key];
            });
        }

        if (byKey) {
            Object.keys(byKey).forEach(key => {
                let byKeyIsMergeable = isMergeableObject(byKey[key]);
                if (isMergeableObject(byKey[key])) {
                    result[key] = merge(result[key] as IStyleDictionary, byKey[key] as IStyleDictionary);
                } else {
                    result[key] = byKey[key];
                }
            });
        }
        return result;
    }

    function isMergeableObject(value: any) {
        let valueAsString = Object.prototype.toString.call(value);
        return !!value
            && typeof value === "object"
            && valueAsString !== "[object RegExp]"
            && valueAsString !== "[object Date]";
    }

    function processStylesByKey(styleType: StyleType, rawStyles: IClientOptionsStylesRaw): IClientOptionsStylesByKey {
        let result: any = {};

        fieldKeys.forEach(key => {
            let styleByType = rawStyles ? rawStyles[styleType] : {};
            let styleByKey = rawStyles ? rawStyles[`${styleType}.${key}`] : {};
            let merged = merge(styleByType, styleByKey);

            result[key] = {
                base: toStyleString(merged),
                focus: toStyleString(merged.focus),
                hover: toStyleString(merged.hover),
                placeholder: toStyleString(merged.placeholder),
            };
        });

        return result;
    }

    function processStyles(rawStyles: IClientOptionsStylesRaw): IClientOptionsStyles {
        let bodyStyles = rawStyles ? rawStyles.body : {};
        let cardTypeStyles = rawStyles ? rawStyles.cardType : {};
        return {
            body: toStyleString(bodyStyles || {}),
            container: processStylesByKey("container", rawStyles),
            label: processStylesByKey("label", rawStyles),
            field: processStylesByKey("field", rawStyles),
            validation: processStylesByKey("validation", rawStyles),
            cardType: toStyleString(cardTypeStyles || {}),
        };
    }

    function processText(text: IClientOptionsText | undefined): IClientOptionsText {
        if (!text) {
            text = {};
        }

        if (!text.labels) {
            text.labels = {};
        }

        if (!text.validation) {
            text.validation = {};
        }

        if (!text.validation.required) {
            text.validation.required = {};
        }

        if (!text.placeholder) {
            text.placeholder = {};
        }

        return text;
    }

}
interface IClientOptionsWithContainerRaw extends IClientOptionsRaw {
    container: HTMLElement;
}

interface IClientOptionsRaw {
    debug: boolean;
    skin: Skin;
    styles: IClientOptionsStylesRaw;
    auth: IAuth;
    cvvMode: CvvMode;
    companyNameMode: CompanyNameMode;
    businessCheckingEnabled: boolean;
    shrinkFields: boolean;
    bypassPostalCodeValidation?: boolean;
    text: IClientOptionsText;
    showPlaceholder: boolean;
    preventAutocomplete: boolean;
    enableCaptcha?: boolean;
    enableCaptchaInteraction?: boolean;
    recaptchaSiteKey?: string;
}

interface IClientOptionsStylesRaw {
    [key: string]: IStyleDictionary;
    body?: IStyleDictionary;
    container?: IStyleDictionary;
    label?: IStyleDictionary;
    field?: IStyleDictionary;
    validation?: IStyleDictionary;
    cardType?: IStyleDictionary;

    "container.cardNumber"?: IStyleDictionary;
    "container.expiration"?: IStyleDictionary;
    "container.cvv"?: IStyleDictionary;
    "container.postalCode"?: IStyleDictionary;
    "container.routingNumber"?: IStyleDictionary;
    "container.accountNumber"?: IStyleDictionary;
    "container.accountType"?: IStyleDictionary;
    "container.bankName"?: IStyleDictionary;
    "container.companyname"?: IStyleDictionary;

    "label.cardNumber"?: IStyleDictionary;
    "label.expiration"?: IStyleDictionary;
    "label.cvv"?: IStyleDictionary;
    "label.postalCode"?: IStyleDictionary;
    "label.routingNumber"?: IStyleDictionary;
    "label.accountNumber"?: IStyleDictionary;
    "label.accountType"?: IStyleDictionary;
    "label.bankName"?: IStyleDictionary;
    "label.companyName"?: IStyleDictionary;

    "field.cardNumber"?: IStyleDictionary;
    "field.expiration"?: IStyleDictionary;
    "field.cvv"?: IStyleDictionary;
    "field.postalCode"?: IStyleDictionary;
    "field.routingNumber"?: IStyleDictionary;
    "field.accountNumber"?: IStyleDictionary;
    "field.accountType"?: IStyleDictionary;
    "field.bankName"?: IStyleDictionary;
    "field.companyName"?: IStyleDictionary;

    "validation.cardNumber"?: IStyleDictionary;
    "validation.expiration"?: IStyleDictionary;
    "validation.cvv"?: IStyleDictionary;
    "validation.postalCode"?: IStyleDictionary;
    "validation.routingNumber"?: IStyleDictionary;
    "validation.accountNumber"?: IStyleDictionary;
    "validation.accountType"?: IStyleDictionary;
    "validation.bankName"?: IStyleDictionary;
    "validation.companyName"?: IStyleDictionary;
}

interface IListenerDictionary {
    [eventType: string]: Function[];
}
