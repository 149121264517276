import {ISuccessCallback, IFailureCallback} from './http';
import {Service} from '../service';
import {ServiceRegistry} from '../service-registry';

export class KryptonService extends Service {
    constructor(services: ServiceRegistry) {
        super(services);
    }

    public log(message: ILogMessage) {
        try {
            this.services.http.post(`web/front_end_logger/${LogLevel[message.log_level]}`, message, () => {}, () => {});
        }
        catch(e) {
        }

    }
}

export enum LogLevel {
    Debug = 0,
    Info = 1,
    Warn = 2,
    Error = 3
}

export interface ILogMessage {
    log_level: LogLevel;
    message: string;
}
