import {IHeightChanged} from '../sender-contracts';
import {Service} from './service';
import {ServiceRegistry} from './service-registry';

export class ReceiverRegistry extends Service {
    private _iframe: HTMLIFrameElement;

    constructor(services: ServiceRegistry, iframe: HTMLIFrameElement) {
        super(services);

        this._iframe = iframe;
    }

    public heightChanged(body: IHeightChanged) {
        this._iframe.style.height = body.newValue + 'px';
    }
}
